import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import(/* webpackChunkName: "home" */ "@/views/Home.vue"),
  },
  {
    path: "/servicos",
    name: "Services",
    component: () =>
      import(/* webpackChunkName: "home" */ "@/views/Services.vue"),
  },
  {
    path: "/contato",
    name: "Contact",
    component: () =>
      import(/* webpackChunkName: "home" */ "@/views/Contact.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  linkExactActiveClass: "active",
  scrollBehavior() {
    document.getElementById("app").scrollIntoView();
  },
});

export default router;
