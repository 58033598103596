<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style lang="scss">
@import "@/assets/scss/styleguide.scss";

* {
  box-sizing: border-box;
  outline: none;
  margin: 0;
  padding: 0;
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0px;
  font-family: var(--font-primary) !important;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

.container {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}
</style>
