import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueGtm from "@gtm-support/vue2-gtm";
import "animate.css";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

Vue.config.productionTip = false;

if (process.env.VUE_APP_GTM_ID) {
  Vue.use(VueGtm, {
    id: process.env.VUE_APP_GTM_ID,
    defer: false,
    compatibility: false,
    nonce: "2726c7f26c",
    enabled: true,
    debug: process.env.NODE_ENV === "development",
    loadScript: true,
    vueRouter: router,
    trackOnNextTick: false,
  });
}

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
